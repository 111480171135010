import { useParams,Link } from "react-router-dom";
import {useEffect} from 'react';

import { useContext } from "react";
import { ListContext } from "../contexts/ListContext";

import { Card, Col, Row, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";


import DOMPurify from 'dompurify';

const BukytalkMessagesDetails = () => {
 const { listeById,listeOne,messageDetailCount2,messageClickCountsBuky } = useContext(ListContext);
  const { id } = useParams();

  useEffect(() => {
    listeById(id, 2); 
  }, []); 

  const isHandleAdd = () => {
    messageDetailCount2();
  };

  const formatTarih = (rawDate) => {
    const date = new Date(rawDate);
    const formattedDate = date.toLocaleDateString('tr-TR');
    return formattedDate;
  };
  const { Title, Text } = Typography;

  //! data.id'ye karşılık gelen tıklama sayısını al
  const clickCount = messageClickCountsBuky[id] || 0;

  const sanitizedContent = DOMPurify.sanitize(listeOne && listeOne[0] && listeOne[0].content);
  
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={12} sm={24} lg={24} xl={24} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
             <Row gutter>
             <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mobile-24">
              <div className="h-full col-content p-20">
                <div className="ant-muse">
                  <Title level={5}>{listeOne && listeOne[0] && listeOne[0].title}</Title>
                  <Paragraph className="lastweek mb-36">
                     <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                     </Paragraph>
                </div>
                <div className="card-footer">
                  <Link to="/bukytalkMessages" className="icon-move-right" href="#pablo" onClick={isHandleAdd}>
                  {<LeftOutlined /> }
                    Geri Dön
                  </Link>
                </div>
              </div>
            </Col> 
            <Col xs={24} md={12} sm={24} lg={12} xl={10} className="col-img centerForm" style={{margin:"30px 0"}}>
              <div className="ant-cret text-right">
                <img
                  src="https://bukytalk.com/images/bg_3.webp"
                  alt="bukytalk"
                  className="border10"
                />
              </div>
            </Col>
            {/* Sağ üst köşeye tarih bilgisini ekleyen kısım */}
          <div style={{ position: 'absolute', top: 0, right: 10, padding: '8px' }}>
            <Text>{listeOne && listeOne[0] && formatTarih(listeOne[0].created_at)}</Text>
          </div>
          {/* Sağ alt köşeye 50 defa okundu gibi yazıyı ekleyen kısım */}
          <div style={{ position: 'absolute', bottom: 0, right: 0, padding: '8px' }}>
             <Text>{`${clickCount} Defa Okundu`}</Text>
          </div>
          </Row> 
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BukytalkMessagesDetails;
