import { useContext,useEffect } from "react";
import { ListContext } from "../contexts/ListContext";

import { Row } from "antd";

import GeneralManagerCard from "./GeneralManagerCard";

const GeneralManager = () => {

  const { allListe,liste } = useContext(ListContext);

  useEffect(() => {
    allListe(1); 
  }, []);


  return (
    <>
       <Row gutter={[24, 0]}>
        {liste &&
          liste.map((data) => <GeneralManagerCard key={data.id} data={data} />)}
      </Row> 
    </>
  );
};

export default GeneralManager;
