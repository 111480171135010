//! React
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useContext, useEffect } from "react";

//! Context
import AutoContextProvider from "./contexts/AutoContext";
import ListContextProvider from "./contexts/ListContext";
import PasswordContextProvider from "./contexts/PasswordContext";


import { AutoContext } from "./contexts/AutoContext";


//! Pages
import Home from "./pages/Home";
 import SignIn from "./pages/SignIn";

import Basin from "./pages/Basin";
import BukytalkMessages from "./pages/BukytalkMessages";
import GeneralManager from "./pages/GeneralManager";
import Raporlar from "./pages/Raporlar";
import Logout from "./pages/Logout";
import NotFound from "./pages/NotFound";
import PasswordChange from "./pages/passwordChange";
import GeneralManagerDetails from './pages/GeneralManagerDetails';
import BukytalkMessagesDetails from './pages/BukytalkMessagesDetails';
import ResetPassword from './pages/resetPassword';
import ConnentMessage from './pages/ConnectMessage'

//!Layouts
import Main from "./components/layout/Main";

//! Css
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

const App = () => {
  const AuthGuard = ({ component: Component, ...rest }) => {
    const { token } = useContext(AutoContext);
    const history = useHistory();

    useEffect(() => {
      if (!token) {
        history.push("/login");
      }
    }, [token, history]);

    return (
      <Route
        {...rest}
        render={(props) => (token ? <Component {...props} /> : null)}
      />
    );
  };

  return (
    <div className="App">
      <AutoContextProvider>
          <PasswordContextProvider>
          <Switch>
            <Route exact path="/login" component={SignIn} />
            <Redirect exact from="/" to="/login" />
            <Route exact path="/passwordChange" component={PasswordChange} />
            <Route exact path="/reset-password/:token" component={ResetPassword} />
            <Route exact path="/logout" component={Logout} />
            <ListContextProvider>
            <Main>
              <AuthGuard exact path="/home" component={Home} />
              <AuthGuard exact path="/generalManager" component={GeneralManager} />
              <AuthGuard exact path="/generalManager/:id" component={GeneralManagerDetails} />
              <AuthGuard exact path="/bukytalkMessages" component={BukytalkMessages} />
              <AuthGuard exact path="/bukytalkMessages/:id" component={BukytalkMessagesDetails} />
              <AuthGuard exact path="/basin" component={Basin} />
              <AuthGuard exact path="/raporlar" component={Raporlar} />
              <AuthGuard exact path="/connectMessage" component={ConnentMessage} />
            </Main>
            </ListContextProvider>
            <Route from="*" to={<NotFound />} />
          </Switch>
          </PasswordContextProvider>
        
      </AutoContextProvider>
    </div>
  );
};

export default App;
