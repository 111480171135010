import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Layout, Button, Row, Col, Typography, Form, Input } from "antd";
import signinbg from "../assets/images/img-signin.jpg";

import { PasswordContext } from "../contexts/PasswordContext";

import { OpenMessage } from "../components/Feedback";

const { Title } = Typography;
const { Content } = Layout;

const PasswordChange = () => {
  const [showError, setShowError] = useState(false);
  const [showMsg, setShowMsg] = useState(false);

  const { emailControl, err, msg } = useContext(PasswordContext);

  const onFinish = async (values) => {
    console.log(values);
    emailControl(values);
  };

  useEffect(() => {
    console.log("Effect executed", err, msg);
    if (msg) {
      setShowMsg(true);
      setShowError(false);
    } else if (err) {
      setShowError(true);
      setShowMsg(false);
    } else {
      setShowError(false);
      setShowMsg(false);
    }
    return () => {
      console.log("Cleanup executed");
      setShowError(false);
      setShowMsg(false);
    };
  }, [err, msg]);

  

  return (
    <>
      { showError && (
        <OpenMessage type={"warning"} message={"Uyarı"} description={err} />
      )}
      { showMsg && (
        <OpenMessage type={"success"} message={"Uyarı"} description={msg} />
      )}
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
              className="centerForm"
            >
              {/* <Title className="mb-15">Bukytalk.com</Title> */}
               <Title className="font-regular text-muted" level={5}>
                  Lütfen Email Adresinizi Giriniz.
                </Title>  
              <Form onFinish={onFinish} layout="vertical" className="row-col">
                <Form.Item
                  className="username"
                  label="Email"
                  name="mail"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen e-postanızı giriniz!",
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    Gönder
                  </Button>
                </Form.Item>
                <p className="font-semibold text-muted">
                  <Link to="/login" className="text-dark font-bold">
                    Geri Dön
                  </Link>
                </p>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signinbg} alt="BukytalkLogo" />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default PasswordChange;