import { Card, Col, Row, Typography } from "antd";
const images = [
  {
    img:require("../assets/images/basin/egirisim-mobil-logo.png").default,
    link:"https://egirisim.com/2021/01/22/bukytalk-hata-yapmaktan-korkmadan-ingilizce-konusabileceginiz-sosyal-platform/"
  },
  {
    img:require("../assets/images/basin/girisim-haberleri-logo.png").default,
    link:"https://www.girisimhaberleri.com/bukytalk-ilk-yatirimini-aldi/"
  },
  {
    img:require("../assets/images/basin/StartupTeknoloji-Mobil-Logo.png").default,
    link:"https://startupteknoloji.com/bukytalk-yarin-fonlaniyor/"
  },
  {
    img:require("../assets/images/basin/istanbul_ticaret.png").default,
    link:"https://istanbulticaretgazetesi.com/tr/bukytalk-ile-daha-akici-ingilizce"
  },
  {
    img:require("../assets/images/basin/btm_logo.png").default,
    link:"https://btm.istanbul/haber/bukytalk-turkiyenin-en-hizli-fonlanan-girisimi-olarak-rekor-kirdi"
  },
  {
    img:require("../assets/images/basin/webrazzi-logo.svg").default,
    link:"https://webrazzi.com/2021/01/22/sosyal-dil-ogrenme-platformu-bukytalk/"
  },
  {
    img:require("../assets/images/basin/lukwise_logo.png").default,
    link:"https://www.lukwise.com/Providers/Detail/Bukytalk-Bilgi-Teknolojileri-Anonim-sirketi"
  },
  {
    img:require("../assets/images/basin/superHaber-logo.png").default,
    link:"https://www.superhaber.com/bukytalk-nedir-ingilizce-nasil-ogrenilir-korkusuzca-konusabilmenin-adresi-bukytalk-ile-ingilizce-ogrenmek-artik-daha-kolay-haber-411495"
  },
  // {
  //   img:require("../assets/images/basin/").default,
  //   link:""
  // },
];

const Basin = () => {
  const { Title } = Typography;
  return (
    <>
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        {images.map((c, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={8} xl={8} className="mb-24">
            <Card bordered={false} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '15rem' }} className="criclebox hover-grow">
              <a href={c.link} target="_blank" rel="noopener noreferrer">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={24}>
                      <span>{c.today}</span>
                      <Title level={3}>
                        <img src={c.img} alt="Resim" style={{ Width: '100%', Height: '100%', objectFit: 'cover' }} />
                      </Title>
                    </Col>
                  </Row>
                </div>
              </a>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Basin;