const NotFound = () => {
  return (
    <div>
      <h2>404 Not Found</h2>
      <p>Üzgünüz, aradığınız sayfa bulunamadı.</p>
    </div>
  );
};

export default NotFound;
