import { useContext, useState } from "react";
import { Button, Row, Col, Form, Input } from "antd";
import { AutoContext } from "../contexts/AutoContext";
import { OpenMessage } from "../components/Feedback";

const ConnentMessage = () => {
  const { userEmail, sendMail } = useContext(AutoContext);
  const [showMsg, setShowMsg] = useState(false);
  const [msg, setMsg] = useState("");
  const [form] = Form.useForm();

  const { TextArea } = Input;
  const onFinish = (values) => {

    console.log("Form Değerleri:", values);
    sendMail(userEmail, values.konu, values.message)
      .then((result) => {
        console.log("Mail gönderme sonucu:", result); // result içinde "Mail Gönderildi..." mesajı veya başka bir değer olmalı
        setMsg(result);
        setShowMsg(true);
        form.resetFields(); // Formdaki alanları temizle
      })
      .catch((error) => {
        setShowMsg(false);
        setMsg(error.message);
        console.error("Mail gönderme hatası:", error.message);
      });

  };

  return (
    <>
      {showMsg && (
        <OpenMessage type={"success"} message={"Mesaj"} description={msg} />
      )}
      <div className="">
        <Row gutter={[24, 0]} className="centerForm">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {/* <Title className="mb-15">Yatırımcı Portalı <br></br>Bukytalk.com</Title> */}
            <Form onFinish={onFinish} layout="vertical" className="row-col" form={form}>
              <Form.Item
                className="username"
                label="Konu"
                name="konu"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Konu Başlığını Yazınız!",
                  },
                ]}
              >
                <Input placeholder="Lütfen Konu Başlığını Yazınız!" />
              </Form.Item>

              <Form.Item
                className="username"
                label="Mesaj"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Lütfen Mesajınızı Yazınız!",
                  },
                ]}
              >
                <TextArea
                  rows={12}
                  placeholder="En Fazla 200 Karakter Girebilirsiniz!"
                  maxLength={200}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Gönder
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ConnentMessage;
