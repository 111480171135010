import { createContext, useState } from "react";
import { get, post } from "../services/index";

export const AutoContext = createContext();

//! Diğer Componentlerle Paylaşmak İstediğimiz Verileri Buraya Alıcaz.
const AutoContextProvider = ({ children }) => {

  // const apiUrl = "http://localhost:5000/auth";
  // const apiUrl2 = "http://localhost:5000/auth/messageMail";

  const apiUrl = "https://yatirimciapi.bukytalk.com/auth";
  const apiUrl2 = "https://yatirimciapi.bukytalk.com/auth/messageMail";

  // const [token, setToken] = useState(localStorage.getItem("token"));
  const [token, setToken] = useState(() => {
    const storedToken = localStorage.getItem("token");
    return storedToken ? storedToken : null;
  });

  const [userName, setUserName] = useState(localStorage.getItem("userName"));
  const [userId, setUserId] = useState(
    parseInt(localStorage.getItem("userId"))
  );
  const [userEmail, setUserEmail] = useState(localStorage.getItem("userEmail"));
  const [err, setErr] = useState(null);

  //! Oturum açma işlemleri
  const login = async (values) => {
    try {
      const data = await post(`${apiUrl}/login`, values);
      localStorage.setItem("token", data.token);
      setToken(data.token);
      setErr(null);
      users(data.token);
    } catch (error) {
      setToken(null);
      setErr(error.message);
    }
  };

  //! Kullanıcı Bilgileri
  const users = async (token) => {
    try {
      const usersData = await get(`${apiUrl}/user-info`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserId(usersData.id);
      setUserEmail(usersData.mail);
      localStorage.setItem("userEmail", usersData.mail);
      localStorage.setItem("userId", usersData.id);
      localStorage.setItem("userName", usersData.adsoyad.toUpperCase());
      setUserName(usersData.adsoyad.toUpperCase());
    } catch (error) {
      console.log(error.message);
    }
  };

  //! Çıkış işlemleri
  const logout = () => {
    setToken(null);
    setUserId(null);
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
  };

  const sendMail = async ( email, konu, message ) => {
    
    try {
      const response = await post(apiUrl2, { mail: email, konu, message });
      return response.message;
    } catch (error) {
      console.error('Mail gönderme hatası:', error.message);
    }
  };

  return (
    <AutoContext.Provider
      value={{ login, logout, token, err, userName, userId, userEmail,sendMail }}
    >
      {children}
    </AutoContext.Provider>
  );
};

export default AutoContextProvider;
