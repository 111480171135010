import { useContext } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";

import { AutoContext } from "../contexts/AutoContext";
import { ListContext } from "../contexts/ListContext";

import DOMPurify from 'dompurify';

const GeneralManagerCard = ({ data }) => {
 
  const { userId } = useContext(AutoContext);
  const { messageDetailsAdd,messageClickCounts } = useContext(ListContext);

  const isHandleAdd = () => {
    messageDetailsAdd(userId, data.id);
  };

  const formatTarih = (rawDate) => {
    // rawDate'i bir JavaScript Date nesnesine çevir
    const date = new Date(rawDate);
    // Tarihi istediğiniz formata dönüştür
    const formattedDate = date.toLocaleDateString('tr-TR'); // 'tr-TR' Türkçe format için
    return formattedDate;
  };

 //! data.id'ye karşılık gelen tıklama sayısını al
 const clickCount = messageClickCounts[data.id] || 0;
 
 const sanitizedContent = DOMPurify.sanitize(data.content);

  const { Title, Text } = Typography;
  return (
    <>
      <Col xs={24} md={12} sm={24} lg={12} xl={12} className="mb-24">
        <Card bordered={false} className="criclebox h-full">
          <Row gutter>
            <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mobile-24">
              <div className="h-full col-content p-20">
                <div className="ant-muse">
                  <Text>Hasancan Üretmenoğlu</Text>
                  <Title level={5}>{data.title}</Title>
                  <Paragraph className="lastweek mb-36">
                    <div dangerouslySetInnerHTML={{ __html: sanitizedContent.slice(0, 100) + " ..." }} />
                  </Paragraph>
                </div>
                <div className="card-footer">
                  <Link
                    to={`/generalManager/${data.id}`}
                    className="icon-move-right"
                    href="#pablo"
                    onClick={isHandleAdd}
                  >
                    {" "}
                    DETAY
                    {<RightOutlined />}
                  </Link>
                </div>
              </div>
            </Col>
            {/* Sağ üst köşeye tarih bilgisini ekleyen kısım */}
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 10,
                padding: "8px",
              }}
            >
              <Text>{formatTarih(data.created_at)}</Text>
            </div>
            {/* Sağ alt köşeye 50 defa okundu gibi yazıyı ekleyen kısım */}
            <div
              style={{
                position: "absolute",
                bottom: 0,
                right: 10,
                padding: "8px",
              }}
            >
               <Text>{`${clickCount} Defa Okundu`}</Text>
            </div>
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default GeneralManagerCard;