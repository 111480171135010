import { Menu, Button } from "antd";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";

function Sidenav() {
  return (
    <>
      <div className="brand">
        <img style={{width:"100px", height:"100px"}} src={logo} alt="Bukytalk" />
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/home">
            <span className="label">Anasayfa</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/generalManager">
            <span className="label">Genel Müdür Mesajları</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/bukytalkMessages">
            <span className="label">Bizden Haberler</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/basin">
            <span className="label">Basın</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/raporlar">
            <span className="label">Denetim Raporları</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/connectMessage">
            <span className="label">Bize Ulaşın</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item key="7">
        <NavLink to="/logout">
            <Button style={{marginTop:"15px"}} type="dashed" danger block ghost>
                 Çıkış
            </Button> 
            </NavLink>
        </Menu.Item>
       
      </Menu>
    </>
  );
}

export default Sidenav;
