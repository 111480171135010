import { Line } from "@ant-design/plots";
import { useContext } from "react";
import { ListContext } from "../contexts/ListContext";

const ChartsParticipant = () => {
  const { participantListData } = useContext(ListContext);

   // Veriyi kontrol et ve işle, sadece bu ayı al
   const currentMonthData = participantListData?.participantList?.filter(item => {
    const currentDate = new Date();
    const itemDate = new Date(item.date);
    return itemDate.getMonth() === currentDate.getMonth() && itemDate.getFullYear() === currentDate.getFullYear();
  })
  .sort((a, b) => {
    // Tarihleri karşılaştır, küçükten büyüğe doğru sırala
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  }) || [];

  // Ant Design çubuk grafiği için config ayarları
  const config = {
    data: currentMonthData,
    xField: 'date',
    yField: 'count',
    label: {
      position: 'middle',
      layout: [{ type: 'interval-adjust-position' }],
    },
  };

  return <Line {...config} />;
};

export default ChartsParticipant;