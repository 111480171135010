import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { AutoContext } from "../contexts/AutoContext";
import { useContext } from "react";
import { OpenMessage } from "../components/Feedback";

import { Layout, Button, Row, Col, Typography, Form, Input } from "antd";

import signinbg from "../assets/images/img-signin.jpg";



const { Title } = Typography;
const { Content } = Layout;


const SignIn = () => {
  const [showError, setShowError] = useState(false);
  const history = useHistory();

  const { login, token, err } = useContext(AutoContext);
  
  const onFinish = (values) => {
    login(values);
  };

  useEffect(() => {
    if (token !== null && token !== undefined && token !== 'undefined' && !err) {
      history.push("/home");
    }
    if (err) {
      setShowError(true);
    }
  }, [token, err, history]);


  return (
    <>
      {showError && <OpenMessage type={'warning'} message={"Uyarı"} description={err} />}

      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Yatırımcı Portalı <br></br>Bukytalk.com</Title>
              <Form
                onFinish={onFinish}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="mail"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen e-postanızı giriniz!",
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Şifre"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen şifrenizi giriniz!",
                    },
                  ]}
                >
                  <Input.Password
                    style={{ borderRadius: "6px" }}
                    placeholder="Password"
                  />
                </Form.Item>

                <p className="font-semibold text-muted">
                  <Link to="/passwordChange" className="text-dark font-bold">
                    Şifremi Unuttum
                  </Link>
                </p>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    Gönder
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signinbg} alt="BukytalkLogo" />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default SignIn;
