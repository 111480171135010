import { createContext, useState, useContext, useEffect } from "react";
import { get, post } from "../services/index";

import { AutoContext } from "./AutoContext";

export const ListContext = createContext();

const ListContextProvider = ({ children }) => {
  const { token } = useContext(AutoContext);
  const [liste, setListe] = useState([]);
  const [listeOne, setListeOne] = useState([]);

  const [participant, setParticipant] = useState(0);
  const [seans, setSeans] = useState(0);
  const [seansListData, setSeansListData] = useState([]);
  const [participantListData, setParticipantListData] = useState([]);

  const [messageClickCounts, setMessageClickCounts] = useState({});
  const [messageClickCountsBuky, setMessageClickCountsBuky] = useState({});

  // const apiUrl = "http://localhost:5000/reports/messages/type";
  // const apiUrl2 = "http://localhost:5000/participant/Count";
  // const apiUrl3 = "http://localhost:5000/seans/Count";

  // const apiUrl4 = "http://localhost:5000/seans/List";
  // const apiUrl5 = "http://localhost:5000/participant/List";

  // const apiUrl6 = "http://localhost:5000/messages/details";

  // const apiUrl7 = "http://localhost:5000/messages/detail";

  const apiUrl = "https://yatirimciapi.bukytalk.com/reports/messages/type";
  const apiUrl2 = "https://yatirimciapi.bukytalk.com/participant/Count";
  const apiUrl3 = "https://yatirimciapi.bukytalk.com/seans/Count";

  const apiUrl4 = "https://yatirimciapi.bukytalk.com/seans/List";
  const apiUrl5 = "https://yatirimciapi.bukytalk.com/participant/List";

  const apiUrl6 = "https://yatirimciapi.bukytalk.com/messages/details";

  const apiUrl7 = "https://yatirimciapi.bukytalk.com/messages/detail";


  const fetchDataFromApi = async () => {
    try {
      const response = await get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.message;
    } catch (error) {
      console.log("Error in fetchDataFromApi:", error);
      throw error;
    }
  };


//! yatirimci_message Tablosundaki haberleri type isteği attığımda Tüm Habeleri bana Dönüyor.
  const allListe = async (type) => {
    try {
      const listData = await fetchDataFromApi();

      if (type) {
        const data = listData.filter((item) => item.type === type);
        setListe(data);
      } else {
        console.log("No Type provided");
      }
    } catch (error) {
      console.log("Error in fetchListById:", error);
    }
    
  };

  //! yatirimci_message Tablosundaki haberleri type göre Bize Verileri Getiriyor.
  const listeById = async (id, type) => {
    try {
      const listData = await fetchDataFromApi();

      if (id) {
        const numericId = parseInt(id, 10);
        const dataOne = listData.filter(
          (item) => item.type === type && item.id === numericId
        );
        setListeOne(dataOne);
      } else {
        console.log("No ID provided");
      }
    } catch (error) {
      console.log("Error in fetchListById:", error);
    }
  };

  //! Seans ve participant tablolarını adetlerini dönüyor.
  const counts = async () => {
    try {
      const adetParticipant = await get(apiUrl2);
      const adetSeans = await get(apiUrl3);
      setParticipant(adetParticipant);
      setSeans(adetSeans);
    } catch (error) {
      console.log(error);
    }
  };

  //! Seansları Ay Bazında Api'den Dönüyor. GRAFIK
  const seansList = async () => {
    try {
      const data = await get(apiUrl4);
      setSeansListData(data);
    } catch (error) {
      console.log(error);
    }
  };

  //! participant Ay Bazında Api'den Dönüyor. GRAFIK
  const participantList = async () => {
    try {
      const data = await get(apiUrl5);
      setParticipantListData(data);
    } catch (error) {
      console.log(error);
    }
  };

  //! Kullanıcılar Hangi Baeri okuduysa onun userId ve okuduğu messageId lerini Kaydettik.
  const messageDetailsAdd = async (userId, messageId) => {
    try {
      const response = await post(apiUrl6, { userId, messageId });

      if (!response.ok) {
        throw new Error("Failed to save message details");
      }

      const data = await response.json();
      console.log(data); // Başarılı yanıtı kontrol et
    } catch (error) {}
  };


  //! type:1 Olan Haberlerin Adedini Dönüyor.
  const messageDetailCount = async () => {
    try {
      const response = await post(apiUrl7, { type: 1 });
      // console.log("COUNTTTTTTTT", response);
      setMessageClickCounts(response);
    } catch (error) {
      console.log(error);
    }
  };

  //! type:2 Olan Haberlerin Adedini Dönüyor.
  const messageDetailCount2 = async () => {
    try {
      const response = await post(apiUrl7, { type: 2 });
      // console.log("COUNTTTTTTTT", response);
      setMessageClickCountsBuky(response);
    } catch (error) {
      console.log(error);
    }
  };

  
  useEffect(() => {
    counts();
    seansList();
    participantList();
    messageDetailCount();
  }, []);

  useEffect(() => {
    messageDetailCount();
    messageDetailCount2();
  },[]);

  return (
    <ListContext.Provider
      value={{
        allListe,
        listeById,
        liste,
        listeOne,
        participant,
        seans,
        seansListData,
        participantListData,
        messageDetailsAdd,
        messageClickCounts,
        messageDetailCount,
        messageClickCountsBuky,
        messageDetailCount2
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

export default ListContextProvider;