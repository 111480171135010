import { useContext,useEffect } from "react";
import { ListContext } from "../contexts/ListContext";

import { Row } from "antd";

import BukytalkMessagesCard from "./BukytalkMessagesCard";

const BukytalkMessages = () => {

  const { allListe,liste } = useContext(ListContext);

  useEffect(() => {
    allListe(2); 
  }, []);


  return (
    <>
       <Row gutter={[24, 0]}>
        {liste &&
          liste.map((data) => <BukytalkMessagesCard key={data.id} data={data} />)}
      </Row> 
    </>
  );
};

export default BukytalkMessages;

  