import { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { AutoContext } from "../contexts/AutoContext";

const AuthGuard = () => {
  const history = useHistory();
  const { token, logout } = useContext(AutoContext);
  const [checkingToken, setCheckingToken] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const checkToken = async () => {
      try {
        const storedToken = localStorage.getItem("token");
        if (!storedToken) {
          await logout();
          history.push("/login");
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          // İsteği iptal etme hatası
        } else {
          // İptal edilmemiş diğer hataları işleyebilirsiniz.
        }
      } finally {
        if (!signal.aborted) {
          setCheckingToken(false);
        }
      }
    };

    // Sayfa geçişlerini dinle
    const unlisten = history.listen(() => {
      if (!checkingToken) {
        setCheckingToken(true);
        checkToken();
      }
    });

    // Component unmount olduğunda listener'ı temizle
    return () => {
      controller.abort(); // İsteği iptal et
      unlisten();
    };
  }, [token, logout, history, checkingToken]);

  return null;
};

export default AuthGuard;