const request = (url, data = false, method = "GET", config = {}) => {
  return new Promise(async (resolve, reject) => {
    const options = {
      method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...config.headers, // Eklenen satır
      },
      ...config, // Eklenen satır
    };

    if (data && method === "POST") {
      options.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, options);
      const result = await response.json();

      if (response.ok) {
        resolve(result);
      } else {
        reject(result);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const post = (url, data) => request(url, data, "POST");
// export const get = (url) => request(url);
export const get = (url, config = {}) => request(url, null, "GET", config);
