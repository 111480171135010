import { useHistory } from "react-router-dom";
import { AutoContext } from "../contexts/AutoContext";
import { useContext, useEffect } from "react";

const Logout = () => {
  const history = useHistory();
  const { logout } = useContext(AutoContext);

  useEffect(() => {
    const handleLogout = async () => {
      await logout();
      history.push("/login");
    };

    handleLogout();
  }, [logout, history]);

  return null;
};

export default Logout;
