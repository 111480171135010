import pdfFile from '../assets/pdf/DenetimRapori.PDF';

const Raporlar = () => {
  return (
    <div>
      <iframe
        title="PDFViewer"
        src={pdfFile}
        style={{ width: '100%', height: '590px' }}
      ></iframe>
    </div>
  );
};

export default Raporlar;
