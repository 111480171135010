import {useContext} from 'react';
import {ListContext} from '../contexts/ListContext';
import { Card, Col, Row, Typography, Carousel } from "antd";

//! Grafik Components 
import ChartSeans from "./chartSeans";
import ChartsParticipant from "./chartParticipant";

import AuthGuard from "../components/AuthGuard";

const Home = () => {

  const { Title } = Typography;

  const {participant,seans}=useContext(ListContext);

   const profile = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
        fill="#fff"
      ></path>
      <path
        d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
        fill="#fff"
      ></path>
      <path
        d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
        fill="#fff"
      ></path>
      <path
        d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
        fill="#fff"
      ></path>
    </svg>,
  ];

  const count = [
    {
      title: "Toplam Kullanıcı Sayımız",
      count: participant.count,
      icon: profile,
    },
    {
      title: "Toplam Açılan Seans Sayımız",
      count: seans.count,
      icon: profile,
    },
  ];

  return (
    <>
      <AuthGuard />

      <div className="layout-content">
        {/* Card  */}
        <Row className="rowgap-vbox card-1 card-2" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="mb-24"
            >
              <Card bordered={false} className="criclebox">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span style={{fontSize:"18px"}}>{c.title}</span>
                      <Title level={3}>
                       {c.count}
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Grafik  */}
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox">
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={24}>
                     <ChartsParticipant />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox">
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={24}>
                    <ChartSeans />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>


         {/* Carousel  */}
         <Row className="rowgap-vbox" gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Carousel autoplay>
              <div>
                <img
                  style={{ width: "100%", height: "400px" }}
                  src="https://bukytalk.com/images/bg_3.webp"
                  alt="Bukytalk"
                />
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "400px" }}
                  src="https://bukytalk.com/images/bg_4.webp"
                  alt="Bukytalk"
                />
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "400px" }}
                  src="https://btm.istanbul/storage/uploads/news/1542/conversions/banner4-min-large.jpg"
                  alt="Bukytalk"
                />
              </div>
              <div>
                <img
                  style={{ width: "100%", height: "400px" }}
                  src="https://media.licdn.com/dms/image/D4D22AQF-Vrk0xGNymQ/feedshare-shrink_800/0/1697024820892?e=1702512000&v=beta&t=JeMiYlxf2IBCSA3fpJSSJPPjKjhnfeSMm7wVtwKneQk"
                  alt="Bukytalk"
                />
              </div>
            </Carousel>
          </Col>
        </Row>





        <section className="sectionBox">
          <div className="container">
            <Row style={{ textAlign: "center" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: "36px",
                    fontWeight: "600",
                  }}
                >
                  Nedir Bu Elçilik?
                </h1>
              </Col>
            </Row>

            <Row className="elciMain" gutter={[24, 0]}>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <img
                  className="img-fluid"
                  src={require("../assets/images/elcilik/elci_1.jpg").default}
                  alt="nedir bu elçilik"
                />
                <p className="iconSira">01</p>
                <h2 className="textBaslik">Takipçilerinizi Ödüllendirin</h2>
                <p className="textMetin">
                  Takipçilerinize özel en az %10 indirim sunarak onları
                  ödüllendirme imkanı
                </p>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <img
                  className="img-fluid"
                  src={require("../assets/images/elcilik/elci_2.jpg").default}
                  alt="nedir bu elçilik"
                />
                <p className="iconSira">02</p>
                <h2 className="textBaslik">Bizim İçin Çok Değerlisiniz</h2>
                <p className="textMetin">
                  Tarafınıza Hediye edeceğimiz seans haklarımız ile
                  İngilizcenizi geliştirme imkanı
                </p>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                <img
                  className="img-fluid"
                  src={require("../assets/images/elcilik/elci_3.jpg").default}
                  alt="nedir bu elçilik"
                />
                <p className="iconSira">03</p>
                <h2 className="textBaslik">Performansınızı Yönetin</h2>
                <p className="textMetin">
                  Kaç takipçinizin üye olduğunu, kaçının satın aldığını ve
                  kazancınızı görüntüleyeceğiniz paneli kullanma hakkı
                </p>
              </Col>
            </Row>

            <Row className="elciFooter">
              <a
                className="sayfaBtn"
                href="https://elcilik.bukytalk.com/"
                target="blank"
              >
                Hemen Elçi ol
              </a>
            </Row>
          </div>
        </section>



        
      </div>
    </>
  );
};

export default Home;