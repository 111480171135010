import { useEffect } from "react";
import { notification } from "antd";

const OpenMessage = ({ type, message, description }) => {
  useEffect(() => {
    const key = `openMessage_${Date.now()}`;

    notification[type]({
      key,
      message,
      description,
    });

    return () => {
      notification.close(key);
    };
  }, [type, message, description]);

  return null;
};

export { OpenMessage };