import { useParams, Link } from "react-router-dom";
import { useEffect } from "react";

import { useContext } from "react";
import { ListContext } from "../contexts/ListContext";

import { Card, Col, Row, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";

import DOMPurify from 'dompurify';

const GeneralManagerDetails = () => {
  const { listeById, listeOne, messageClickCounts, messageDetailCount } =
    useContext(ListContext);
  const { id } = useParams();

  useEffect(() => {
    listeById(id, 1);
  }, []);

  const isHandleAdd = () => {
    messageDetailCount();
  };

  const formatTarih = (rawDate) => {
    // rawDate'i bir JavaScript Date nesnesine çevir
    const date = new Date(rawDate);
    // Tarihi istediğiniz formata dönüştür
    const formattedDate = date.toLocaleDateString("tr-TR"); // 'tr-TR' Türkçe format için
    return formattedDate;
  };

  const { Title, Text } = Typography;

  const sanitizedContent = DOMPurify.sanitize(listeOne && listeOne[0] && listeOne[0].content);

  //! data.id'ye karşılık gelen tıklama sayısını al
  const clickCount = messageClickCounts[id] || 0;

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={12} sm={24} lg={24} xl={24} className="mb-24">
          <Card bordered={false} className="criclebox h-full">
            <Row gutter>
              <Col
                xs={24}
                md={12}
                sm={24}
                lg={24}
                xl={24}
                className="mobile-24"
              >
                <div className="h-full col-content p-20">
                  <div className="ant-muse">
                    <Title level={5}>
                      {listeOne && listeOne[0] && listeOne[0].title}
                    </Title>
                     <Paragraph className="lastweek mb-36">
                     <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                     </Paragraph>
                  </div>
                  <div className="card-footer">
                    <Link
                      to="/generalManager"
                      className="icon-move-right"
                      href="#pablo"
                      onClick={isHandleAdd}
                    >
                      {<LeftOutlined />}
                      Geri Dön 
                    </Link>
                  </div>
                </div>
              </Col>
              {/* Sağ üst köşeye tarih bilgisini ekleyen kısım */}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 10,
                  padding: "8px",
                }}
              >
                <Text>
                  {listeOne &&
                    listeOne[0] &&
                    formatTarih(listeOne[0].created_at)}
                </Text>
              </div>
              {/* Sağ alt köşeye 50 defa okundu gibi yazıyı ekleyen kısım */}
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  padding: "8px",
                }}
              >
                <Text>{`${clickCount} Defa Okundu`}</Text>
              </div>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default GeneralManagerDetails;
