import { createContext, useState, useEffect } from "react";
import { post } from "../services/index";

export const PasswordContext = createContext();

const PasswordContextProvider = ({ children }) => {
 
  // const apiUrl = "http://localhost:5000/auth/forgot-password";
  // const apiUrl2 = "http://localhost:5000/auth/reset-password";

  const apiUrl = "https://yatirimciapi.bukytalk.com/auth/forgot-password";
  const apiUrl2 = "https://yatirimciapi.bukytalk.com/auth/reset-password";

  const [msg, setMsg] = useState(null);
  const [err, setErr] = useState(null);
  const [data, setData] = useState(null);

  const emailControl = async (email) => {
    try {
      const result = await post(apiUrl, email);
      // console.log("PASSWORD:::::::", result);
      setMsg(result.message);
      setErr(null);
      setData(result);
    } catch (error) {
      console.log("ERROR------", error);
      setMsg(null);
      setErr(error.message);
      setData(null);
    }
  };

  const passwordChange = async (token, values, onSuccess) => {
    const requestData = {
      newPassword1: values.newPassword1,
      newPassword2: values.newPassword2,
      passwordtoken: token,
    };
  
    try {
      const result = await post(apiUrl2, requestData);
      console.log("API:::::::", result);
      if (onSuccess) {
        onSuccess(); 
      }
    } catch (error) {
      console.error("API Hatası:", error);
    }
  };
  

  // useEffect(() => {
  //   setTimeout(() => {
  //     setMsg(null);
  //     setErr(null);
  //     setData(null);
  //   }, 2000);
  // });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMsg(null);
      setErr(null);
      setData(null);
    }, 2000);
  
    // Bu clean-up fonksiyonu, component unmount olduğunda veya
    // dependency (bağımlılık) değiştiğinde çalışır.
    return () => clearTimeout(timeoutId);
  }, [msg, err, data]); // Dependency array ekledik

  return (
    <PasswordContext.Provider
      value={{ emailControl, err, msg, passwordChange }}
    >
      {children}
    </PasswordContext.Provider>
  );
};

export default PasswordContextProvider;