import { useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { Layout, Button, Row, Col, Typography, Form, Input } from "antd";

import signinbg from "../assets/images/img-signin.jpg";

import { OpenMessage } from "../components/Feedback";

import { PasswordContext } from "../contexts/PasswordContext";

const { Title } = Typography;
const { Content } = Layout;

const ResetPassword = () => {
  const [showError, setShowError] = useState(false);
  const [msg, setMsg] = useState("");
  const [err, setErr] = useState("");

  const { token } = useParams();
  const history = useHistory();

  const { passwordChange } = useContext(PasswordContext);

  const onFinish = async (values) => {
    console.log("TIKLANDI");
    console.log(values);
  
    const { newPassword1, newPassword2 } = values;
  
    if (newPassword1 !== newPassword2) {
      setShowError(true);
      console.log("MERHABA");
      setErr("Şifreler Eşleşmedi.");
    } else {
      if (token) {
        try {
          await passwordChange(token, values);
          setShowError(true);
          setMsg("Giriş Yapabilirsiniz. Şifreniz Değiştirildi.");
          setTimeout(() => {
            history.push("/login");
          }, 2000);
        } catch (error) {
          console.error("Şifre değiştirme hatası:", error);
          setShowError(true);
          setErr("Şifre değiştirme sırasında bir hata oluştu.");
        }
      } else {
        console.log("Token Bulunamadı.....");
        setErr("Token Bulunamadı.");
      }
    }
  };
  
  


    return (
    <>
       {showError && err && (
      <OpenMessage type={"warning"} message={"Uyarı"} description={err} />
    )}
    {showError && msg && (
      <OpenMessage type={"success"} message={"Tebbrikler"} description={msg} />
    )}
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
              className="centerForm"
            >
              <Title className="mb-15">Şifre Değiştirme</Title>
              <Form onFinish={onFinish} layout="vertical" className="row-col">
                <Form.Item
                  className="username"
                  label="Şifre"
                  name="newPassword1"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Şifre giriniz!",
                    },
                  ]}
                >
                  <Input.Password
                    style={{ borderRadius: "6px" }}
                    placeholder="Şifre Giriniz"
                  />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Şifre"
                  name="newPassword2"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen şifrenizi giriniz!",
                    },
                  ]}
                >
                  <Input.Password
                    style={{ borderRadius: "6px" }}
                    placeholder="Password"
                  />
                </Form.Item>

                <p className="font-semibold text-muted">
                  <Link to="/login" className="text-dark font-bold">
                    Geri Dön
                  </Link>
                </p>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    Gönder
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signinbg} alt="BukytalkLogo" />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default ResetPassword;
